<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-data-table
      :headers="headers"
      :items="listUsers"
      :server-items-length="usersMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      show-expand
      single-expand
      :footer-props="{ 'items-per-page-options': [5, 30, 50] }"
    >
      <template v-slot:item.image="{ item }">
        <v-img
          v-if="item.image"
          :src="item.image.url"
          max-height="50"
          max-width="50"
        />
        <v-img v-else src="@/assets/user.png" max-height="30" max-width="30" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="$admin.can('user-update')"
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          class="mr-2"
          v-if="item.is_editable_password"
          @click="openUpdatePWForm(item)"
        >
          mdi-lock-reset
        </v-icon>
        <v-icon
          v-if="item.status == 'active'"
          color="red darken-2"
          @click="inactivateUser(item)"
        >
          mdi-account-off
        </v-icon>
        <v-icon v-else color="green darken-2" @click="activateUser(item)">
          mdi-account-check
        </v-icon>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("users") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <delete-user v-if="$admin.can('user-delete')"></delete-user>
          <inactivate-user v-if="$admin.can('user-update')"></inactivate-user>
          <update-password-user
            v-if="$admin.can('user-update')"
          ></update-password-user>
          <edit-user v-if="$admin.can('user-update')"></edit-user>
        </v-toolbar>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="options.search"
              :label="$t('search')"
              class=""
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
  components: {
    EditUser: () => import("./dialogs/EditUser.vue"),
    DeleteUser: () => import("./dialogs/DeleteUser.vue"),
    InactivateUser: () => import("./dialogs/InactivateUser.vue"),
    UpdatePasswordUser: () => import("./dialogs/UpdatePasswordUser.vue"),
  },

  computed: {
    ...mapGetters({
      listUsers: "users/listUsers",
      usersMeta: "users/meta",
    }),
  },

  watch: {
    options: {
      handler: debounce(async function () {
        this.is_loading_user = true;
        await this.$store.dispatch("users/list", this.options).then(() => {
          this.is_loading_user = false;
          if (this.options.page > this.usersMeta?.lastPage) {
            this.options.page = 1;
          }
        });
      }, 1000),
      deep: true,
    },
  },

  data() {
    return {
      options: {},
      is_loading_user: false,
      headers: [
        {
          text: this.$t("id"),
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: this.$t("image"),
          align: "start",
          value: "image",
          sortable: false,
        },
        {
          text: this.$t("name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("email"),
          value: "email",
        },
        {
          text: this.$t("store"),
          value: "profile.store_name",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    editItem(item) {
      this.$store.dispatch("users/openEditForm", item);
    },

    async activateUser(item) {
      try {
        await this.$store.dispatch(
          "users/updateStatus",
          Object.assign({}, { id: item.id, status: "active" })
        );
        this.$store.dispatch(
          "alerts/success",
          this.$t("activated_successfully")
        );
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    async inactivateUser(item) {
      this.$store.dispatch("users/openInactivateUserForm", item);
    },

    async openUpdatePWForm(item) {
      this.$store.dispatch("users/openUpdatePWForm", item);
    },
  },
};
</script>
